export const useGeoEntityStore = defineStore('geoEntity', {
  state: () => ({
    geoEntities: [],
  }),
  actions: {
    async fetchGeoEntities() {
      const supabase = useSupabaseClient()
      const { data } = await supabase
        .from('geo_entities')
        .select('id, iso31661Alpha2:iso_3166_1_alpha_2, nameEn:name_en')
        .not('iso_3166_1_alpha_2', 'is', null)
      console.log('store:geoEntity: fetchGeoEntities: data: ', data.length)
      if (data) {
        this.geoEntities = data
      }
    },
  },
  getters: {
    getGeoEntityById: (state) => (id) => {
      return state.geoEntities.find((geoEntity) => geoEntity.id === id)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGeoEntityStore, import.meta.hot))
}
